.navbar {
  box-shadow: none;
  flex-grow: 1;
}

.navbar .toolbar {
  width: 100%;
  margin: 0 auto;
  width: 90%;
  padding-right: 0;
  padding-left: 0;
}

.navbar-brand {
  height: 65px;
  display: flex;
  align-items: center;
  z-index: 10;
  -webkit-filter: drop-shadow(2px 2px 2px color(white));
  filter: drop-shadow(2px 2px 2px color(white));
  cursor: pointer;
}

.navbar-brand img {
  vertical-align: middle;
  height: 31px;
}

.navbar-fill {
  flex-grow: 1;
}

.selected {
  color: #56c2e1 !important;
}

.navbar-link {
  display: inline-flex;
  justify-content: flex-end;
}

.navbar-link .main-link {
  display: flex;
  align-items: center;
  margin-left: 18px;
  margin-right: 18px;
  height: 20px !important;
  vertical-align: top;
}

.navbar-link .social-media-link {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: top;
}

.navbar-link a {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12.5px;
  font-weight: 600;
}

.navbar-link a:hover {
  color: #56c2e1 !important;
  transition: 0.3s;
  opacity: 0.8;
}

.navbar-link .social-media-link:hover svg {
  color: #56c2e1 !important;
  transition: 0.3s;
  opacity: 0.8;
}

@media (max-width: 1280px) {
  .navbar-reports {
    width: 90%;
  }

  .navbar-reports .navbar-link .main-link {
    margin-right: 15px;
    margin-left: 15px;
  }

  .navbar-reports .navbar-link a {
    font-size: 12px;
  }
}

@media (max-width: 1180px) {
  .navbar-reports .navbar-link .main-link {
    margin-right: 10px;
    margin-left: 10px;
  }

  .navbar-reports .navbar-link a {
    font-size: 11px;
  }
}