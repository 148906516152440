
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  z-index: 1;
  padding-top: 22px;
  top: 16px;
  left: 50%;
  transform: translate(-50%, 0);
}

.dropdown-links {
  border-top: 2px solid #56c2e1;
  background-color: #282D3C;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding-top: 12px;
  padding-bottom: 12px;
}

.dropdown-content .dropdown-item {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.news-dropdown-content {
  min-width: 100px;
}

.reports-dropdown-content {
  min-width: 150px;
}

.dropdown-divider{
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.navbar-item, .dropdown {
  display: flex;
  align-items: center;
}