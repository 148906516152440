.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(90deg, #8e2de2 0%, #56c2e1 100%);
}

.login-backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #070707;
  opacity: 45%;
  z-index: 1;
  height: 100vh;
}

.login-form, .alert-container {
  width: 500px;
  z-index: 2;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 50px;
  background-color: rgba(255, 255, 255, 0.7);
}

.login-form .MuiInputBase-input {
  padding: 10px 0 10px;
}

.login-form .MuiInput-underline:before {
  border-bottom: 1px solid rgba(40,45,60,0.42);
}

.login-form .MuiInput-underline:hover:before {
  border-bottom: 1px solid rgba(40,45,60,0.42) !important;
}

.login-form .form-logo {
  margin: 0 auto;
}

.login-form .form-logo img{
  height: 120px;
}

.login-form .login-text-field {
  margin-top: 10px;
}

.login-form .login-button {
  margin-top: 40px;
}

/* Change Autocomplete styles in Chrome*/
#email:-webkit-autofill, #password:-webkit-autofill,
#email:-webkit-autofill:hover, #password:-webkit-autofill:hover,
#email:-webkit-autofill:focus, #password:-webkit-autofill:focus {
  -webkit-text-fill-color: #070707 !important;
  transition: background-color 5000s ease-in-out 0s !important;
}