.reports-table {
  padding-right: 1.5em;
  padding-left: 1.5em;
  padding-top: 50px;
  padding-bottom: 50px;
}

.reports-table .table-photo {
  display: flex;
  justify-content: center;
}

.action-text-button {
  display: flex;
  justify-content: center;
}

.action-text-button .action-text {
  margin-left: 3px;
}

#m--table--filter--row {
  background-color: rgba(224, 224, 224, 0.4);
}

button[aria-label="Export"] {
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 19px;
  height: 42px;
  padding: 0 30px;
  margin-top: 3px;
  margin-right: 8px;
}

button[aria-label="Export"]:hover {
  background-color: rgba(0, 0, 0, 0.54) !important;
  opacity: 0.8;
}

button[aria-label="Export"] .action-text-button {
  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.reports-table>div>div>div>div>div[style*="display: flex;"],
.reports-table>div>div>div>div>div>span>div[style*="display: flex;"] {
  flex-direction: row-reverse !important;
}

/*input[aria-invalid="true"]::placeholder {
  color: #ff7070;
  opacity: 1;
}*/

.green-text {
  display: block;
  margin-top: 5px;
  color: #008000 !important;
}

tbody tr:not(#m--table--filter--row) {
  vertical-align: top;
}

tbody tr:not(#m--table--filter--row) td {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

tbody tr:not(#m--table--filter--row) td:first-child {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

tbody td a {
  word-break: break-all;
}

tbody td ul {
  padding-inline-start: 20px;
}