.file-error-container {
  display: flex;
  justify-content: center;
}

.file-error-text {
  color: #ff7070 !important;
}

@media (max-width: 1440px) {
  .file-container {
    display: flex;
    flex-direction: column;
  }

  .file-container .dropzone {
    width: 180px;
  }

  .file-container aside {
    margin-top: 10px;
  }
}