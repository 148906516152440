@import url(https://use.typekit.net/xfc0bzz.css);
/* Import Aileron font */

body {
  margin: 0;
  font-family: 'aileron', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  z-index: 1;
  padding-top: 22px;
  top: 16px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.dropdown-links {
  border-top: 2px solid #56c2e1;
  background-color: #282D3C;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding-top: 12px;
  padding-bottom: 12px;
}

.dropdown-content .dropdown-item {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.news-dropdown-content {
  min-width: 100px;
}

.reports-dropdown-content {
  min-width: 150px;
}

.dropdown-divider{
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.navbar-item, .dropdown {
  display: flex;
  align-items: center;
}
.navbar {
  box-shadow: none;
  flex-grow: 1;
}

.navbar .toolbar {
  width: 100%;
  margin: 0 auto;
  width: 90%;
  padding-right: 0;
  padding-left: 0;
}

.navbar-brand {
  height: 65px;
  display: flex;
  align-items: center;
  z-index: 10;
  -webkit-filter: drop-shadow(2px 2px 2px color(white));
  filter: drop-shadow(2px 2px 2px color(white));
  cursor: pointer;
}

.navbar-brand img {
  vertical-align: middle;
  height: 31px;
}

.navbar-fill {
  flex-grow: 1;
}

.selected {
  color: #56c2e1 !important;
}

.navbar-link {
  display: inline-flex;
  justify-content: flex-end;
}

.navbar-link .main-link {
  display: flex;
  align-items: center;
  margin-left: 18px;
  margin-right: 18px;
  height: 20px !important;
  vertical-align: top;
}

.navbar-link .social-media-link {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: top;
}

.navbar-link a {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12.5px;
  font-weight: 600;
}

.navbar-link a:hover {
  color: #56c2e1 !important;
  transition: 0.3s;
  opacity: 0.8;
}

.navbar-link .social-media-link:hover svg {
  color: #56c2e1 !important;
  transition: 0.3s;
  opacity: 0.8;
}

@media (max-width: 1280px) {
  .navbar-reports {
    width: 90%;
  }

  .navbar-reports .navbar-link .main-link {
    margin-right: 15px;
    margin-left: 15px;
  }

  .navbar-reports .navbar-link a {
    font-size: 12px;
  }
}

@media (max-width: 1180px) {
  .navbar-reports .navbar-link .main-link {
    margin-right: 10px;
    margin-left: 10px;
  }

  .navbar-reports .navbar-link a {
    font-size: 11px;
  }
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(90deg, #8e2de2 0%, #56c2e1 100%);
}

.login-backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #070707;
  opacity: 45%;
  z-index: 1;
  height: 100vh;
}

.login-form, .alert-container {
  width: 500px;
  z-index: 2;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 50px;
  background-color: rgba(255, 255, 255, 0.7);
}

.login-form .MuiInputBase-input {
  padding: 10px 0 10px;
}

.login-form .MuiInput-underline:before {
  border-bottom: 1px solid rgba(40,45,60,0.42);
}

.login-form .MuiInput-underline:hover:before {
  border-bottom: 1px solid rgba(40,45,60,0.42) !important;
}

.login-form .form-logo {
  margin: 0 auto;
}

.login-form .form-logo img{
  height: 120px;
}

.login-form .login-text-field {
  margin-top: 10px;
}

.login-form .login-button {
  margin-top: 40px;
}

/* Change Autocomplete styles in Chrome*/
#email:-webkit-autofill, #password:-webkit-autofill,
#email:-webkit-autofill:hover, #password:-webkit-autofill:hover,
#email:-webkit-autofill:focus, #password:-webkit-autofill:focus {
  -webkit-text-fill-color: #070707 !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast {
  font-family: "aileron", sans-serif !important;
}
.file-error-container {
  display: flex;
  justify-content: center;
}

.file-error-text {
  color: #ff7070 !important;
}

@media (max-width: 1440px) {
  .file-container {
    display: flex;
    flex-direction: column;
  }

  .file-container .dropzone {
    width: 180px;
  }

  .file-container aside {
    margin-top: 10px;
  }
}
.reports-table {
  padding-right: 1.5em;
  padding-left: 1.5em;
  padding-top: 50px;
  padding-bottom: 50px;
}

.reports-table .table-photo {
  display: flex;
  justify-content: center;
}

.action-text-button {
  display: flex;
  justify-content: center;
}

.action-text-button .action-text {
  margin-left: 3px;
}

#m--table--filter--row {
  background-color: rgba(224, 224, 224, 0.4);
}

button[aria-label="Export"] {
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 19px;
  height: 42px;
  padding: 0 30px;
  margin-top: 3px;
  margin-right: 8px;
}

button[aria-label="Export"]:hover {
  background-color: rgba(0, 0, 0, 0.54) !important;
  opacity: 0.8;
}

button[aria-label="Export"] .action-text-button {
  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.reports-table>div>div>div>div>div[style*="display: flex;"],
.reports-table>div>div>div>div>div>span>div[style*="display: flex;"] {
  flex-direction: row-reverse !important;
}

/*input[aria-invalid="true"]::placeholder {
  color: #ff7070;
  opacity: 1;
}*/

.green-text {
  display: block;
  margin-top: 5px;
  color: #008000 !important;
}

tbody tr:not(#m--table--filter--row) {
  vertical-align: top;
}

tbody tr:not(#m--table--filter--row) td {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

tbody tr:not(#m--table--filter--row) td:first-child {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

tbody td a {
  word-break: break-all;
}

tbody td ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
